
import { FarmerCard, CardTypes } from "@/store/modules/card/card.types";
import { DefaultState, Pagination } from "@/types/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { currency, slash } from "@/filters/utils";

const card = namespace("Card");

@Component({
  components: {
    NoData: () => import("@/components/utils/NoData.vue"),
  },
  filters: {
    currency,
    slash,
  },
})
export default class CardsView extends Vue {
  @Prop({ default: true }) pagination?: boolean;
  @Prop({ default: "" }) query?: string;

  @card.State("cards")
  public cards!: FarmerCard[];

  @card.State("add")
  public addCard!: DefaultState;

  @card.State("pages")
  public pageCount!: number;

  @card.State("loadingCard")
  public loadingCards!: boolean;

  @card.Mutation(CardTypes.SET_ADD_CARD_DIALOG)
  public setAddCard!: (addCard: boolean) => void;

  @card.Action(CardTypes.LOAD_CARDS)
  public loadCards!: (pagination: Pagination) => void;

  public page = 1;

  @Prop({ required: true, default: 10 }) public pageSize!: number;

  public headers = [
    {
      text: "#ID",
      sortable: true,
      value: "id",
    },
    {
      text: "CARD NO",
      sortable: true,
      value: "card_no",
    },
    {
      text: "NAME",
      sortable: true,
      value: "name",
    },
    {
      text: "CREATED",
      sortable: true,
      value: "created_at",
    },
  ];

  closeAddCardDialog(): void {
    this.setAddCard(false);
  }

  openAddCardDialog(): void {
    this.setAddCard(true);
  }

  @Watch("pageSize")
  handlePageSizeChange(): void {
    this.page = 1;
    this.queryCards();
  }

  @Watch("page")
  handlePageChange(): void {
    this.queryCards();
  }

  queryCards(): void {
    this.loadCards({
      page: this.page,
      limit: this.pageSize,
      query: this.query,
    });
  }

  mounted(): void {
    if (this.cards.length == 0) this.queryCards();
  }
}
